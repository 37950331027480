<template>
  <v-card 
    :loading="loading"
    max-width="600"
  >
    <v-card-text id="map">
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    customerAddress: {
      type: Object,
      default: () => {}
    },
    showCustomerAddress: {
      type: Boolean,
      default: false
    },
    addClickEvent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loader: null,
      geocoder: null,
      map: null,
      marker: null,
      geoCoordinates: {},
      loading: true,
      mapOptions: {
        zoom: 12,
        center: {
          lat: 19.0123594,
          lng: -98.21670429999999
        }
      }
    }
  },
  created() {
    // console.log('Me acabo de crear');
  },
  mounted() {
    // console.log('Me acabo de montar');
    if (!window.google) {
      this.initMapWithLoder()
    } else {
      this.initMapWithoutLoader()
    }
    //this.initMap()
  },
  computed: {
    ...mapState(['mapsLoader']),
    customerLocation() {
      return this.customerAddress.latitud ? `${this.customerAddress.latitud}, ${this.customerAddress.longitud}` : ''
    },
  },
  watch: {
    showCustomerAddress() {
      if (!window.google) {
        this.initMapWithLoder()
      } else {
        this.initMapWithoutLoader()
      }
    }
  },
  methods: {
    async initMap() {
      // console.log("geo coordinates: ", this.geoCoordinates);
      //await this.mapsLoader.load().then(async () => {
        this.map = new google.maps.Map(document.getElementById('map'), this.mapOptions)
        this.marker = new google.maps.Marker()
        this.geocoder = new google.maps.Geocoder()
        //let map = new google.maps.Map(document.getElementById('map'), mapOptions)
        if (this.showCustomerAddress) {
          // console.log('Se debe mostrar direccion de cliente');
          if (this.customerAddress.latitud && this.customerAddress.longitud) {
            this.geoCoordinates = {
              lat: typeof this.customerAddress.latitud === 'string' ? parseFloat(this.customerAddress.latitud) : this.customerAddress.latitud,
              lng: typeof this.customerAddress.longitud === 'string' ? parseFloat(this.customerAddress.longitud) : this.customerAddress.longitud
            }
            // console.log('Si tiene coordenadas')
            this.map.setCenter(this.geoCoordinates)
            this.setMarkerByLatLng(this.map, 
              this.marker, 
              'Direccion del cliente', 
              this.geoCoordinates)
          } else {
            // ('No tiene coordenadas');
            this.setMarkerByGeocode(this.map, 
              this.marker, 
              'Direccion del cliente por geocode', 
              this.geocoder, 
              this.customerAddress)
          }
        }
        if (this.addClickEvent) {
          this.map.addListener('click', async (mapsMouseEvent) => {
            // console.log('Hizo click el usuario');
            const latlng = mapsMouseEvent.latLng
            // console.log('latitude: ', latlng.lat())
            // console.log('longitude: ', latlng.lng())
            this.geoCoordinates.lat = latlng.lat()
            this.geoCoordinates.lng = latlng.lng()
            this.setMarkerByLatLng(this.map, this.marker, 'Posicion seleccionada', this.geoCoordinates)
            const selectedAddress = await this.getCustomerAddresByReverseGeocode(this.geocoder, this.geoCoordinates)
          })
        }
        setTimeout(() => { this.loading = false}, 1500)
      //})
    },
    async setMarkerByGeocode(map, marker, markerTitle, geocoder, customerAddress) {
      const customersAddressStr = `${customerAddress.calle},${customerAddress.numero_exterior},${customerAddress.colonia},${customerAddress.codigo_postal},${customerAddress.estado},${customerAddress.ciudad}`
      await geocoder.geocode({ 
        'address': customersAddressStr,
        componentRestrictions: {
          country: 'MX'
        }
      }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location
          customerAddress.latitud = location.lat()
          customerAddress.longitud = location.lng()

          let customerlatlng = new google.maps.LatLng(customerAddress.latitud, customerAddress.longitud);
          map.setCenter(customerlatlng)
          marker.setPosition(customerlatlng)
          marker.setTitle(markerTitle)
          marker.setMap(map)
        }
      })
    },
    setMarkerByLatLng(map, marker, markerTitle, coordinates) {
      marker.setPosition({lat: coordinates.lat, lng: coordinates.lng})
      marker.setTitle(markerTitle)
      marker.setMap(map)
    },
    async getCustomerAddresByReverseGeocode(geocoder, coordinates) {
      const selectedAddress = {}
      await geocoder.geocode(
        {
          location: {lat: coordinates.lat, lng: coordinates.lng}
        }, 
        (results, status) => {
          if (status === 'OK') {
            if (results[0].address_components.length === 7) {
              const address_components = results[0].address_components
              selectedAddress.calle = address_components[1].long_name
              selectedAddress.numero_exterior = address_components[0].long_name
              selectedAddress.colonia = address_components[2].long_name
              selectedAddress.ciudad = address_components[3].short_name
              selectedAddress.estado = address_components[4].long_name
              selectedAddress.codigo_postal = address_components[6].long_name
              selectedAddress.latitud = coordinates.lat
              selectedAddress.longitud = coordinates.lng
              this.updateAddress(selectedAddress)
              return selectedAddress
            }
          }
        })
      return selectedAddress
    },
    updateAddress(selectedAddress) {
      this.$emit('updateAddress', selectedAddress)
    },
    initMapWithLoder() {
      // console.log('desde -with Loader-');
      this.mapsLoader.load().then(async () => {
        this.initMap()
      })
    },
    initMapWithoutLoader() {
      // console.log('desde -without Loader-');
      this.initMap()
    }
  }
}
</script>

<style scoped>
#map {
  height: 452px;
  width: 100%;
}
</style>